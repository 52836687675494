// src/api/api.js
import axios from 'axios';
import store from '../store';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,  
  // baseUrl:process.env.REACT_APP_LOCAL_URL,
  withCredentials: true, // Include credentials in the request
});

// Add a request interceptor to include the JWT token in the headers
api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default api;

