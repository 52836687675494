// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2', // Replace with your desired primary color
    },
    secondary: {
      main: '#d9534f', // Replace with your desired secondary color
    },
    white: {
      main: '#ffffff'
    },
    wlprimary: {
      main: '#169bd5'
    }
  },
  typography: {
    body2: {
      fontSize: 14
    },
    h2: {
      fontFamily: 'inherit',
      fontWeight: 500,
      lineHeight: 1.1,
      color: 'inherit'
    },
    // fontSize: '14px',
    
    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  }
});

export default theme;
