export function getCookieExpirationDate(cookieName) {
    // Get all cookies as a string.
    var cookies = document.cookie;
  
    // Split the cookies string into an array of individual cookies.
    var cookieArray = cookies.split('; ');
  
    // Loop through the array to find the cookie with the specified name.
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      var cookieParts = cookie.split('=');
      var name = cookieParts[0];
      if (name === cookieName) {
        // Get the cookie value.
        var value = cookieParts[1];
        
        // Parse the cookie value to a Date object.
        var expirationDate = new Date(decodeURIComponent(value));
  
        return expirationDate;
      }
    }
  
    // If the cookie is not found, return null or some other default value.
    return null;
  }
  
  // Usage example
  var cookieName = 'myCookieName';
  var expirationDate = getCookieExpirationDate(cookieName);
  
  if (expirationDate) {
    console.log('The expiration date of the cookie is:', expirationDate);
  } else {
    console.log('Cookie not found or has no expiration date.');
  }
  