// slices/authSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { deleteCookies } from "../utils/helpers/deleteCookies";


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    error: null,
    loading: false,
  },
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
    
      state.loading = false;
      state.user = action.payload;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
        localStorage.removeItem('token')
        deleteCookies()
    
        state.user = null;
        state.error = null;
      },
  },
});

export const { loginRequest, loginSuccess, loginFailure,logout } = authSlice.actions;

export default authSlice.reducer;
