import { getCookieExpirationDate } from "./getExpirationDate";

export function deleteExpiredCookies() {
  // Get all cookies as a string.
  var cookies = document.cookie;

  // Split the cookies string into an array of individual cookies.
  var cookieArray = cookies.split('; ');

  // Get the current date/time.
  var currentTime = new Date();

  // Loop through the cookies to check their expiration dates.
  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i];
    var cookieParts = cookie.split('=');
    var name = cookieParts[0];
    var expirationDate = getCookieExpirationDate(name);

    if (expirationDate) {
      // The cookie has expired, delete it.
      document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }
}
export const deleteCookies=()=>{
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC domain=.ipexservices.com; path=/;';
  }
  var cookiees = document.cookie.split("; ");
  for (var c = 0; c < cookiees.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase = encodeURIComponent(cookiees[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
      var p = window.location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      };
      d.shift();
    }
  }
  function deleteCookiesForSubdomainAndDomain(subdomain, domain) {
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
      var cookieName = cookies[c].split(";")[0].split("=")[0];
      var cookieDomain = domain;

      // If the subdomain is not empty, append it to the cookie domain
      // if (subdomain) {
      //     cookieDomain =  '.' + domain;
      // }

      // Delete the cookie for the specified domain
      document.cookie = encodeURIComponent(cookieName) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + cookieDomain + ' ;path=/';
    }
  }

  // Example usage to delete cookies for the subdomain and domain:
  deleteCookiesForSubdomainAndDomain('dfdf', '.ipexservices.com');
}
export function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  // Return null if the cookie is not found
  return null;
}