import React from 'react'

function NotFound() {
  return (
    <div style={{height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
        // : column;
        // : center;
        // justify-content: center;
        }}>
        <h1>404</h1>
        <div style={{display:'inline-block',textAlign:'left',lineHeight:'49px',height:'49px',verticalAlign:'middle'}}>
            <h2 style={{ fontSize:'14px',fontWeight:'normal',lineHeight:'49px',margin:0,padding:0 }}>
            This page could not be found
            </h2>

        </div>
    </div>
  )
}

export default NotFound