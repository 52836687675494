// slices/authSlice.js

import { createSlice } from "@reduxjs/toolkit";


const taskSlice = createSlice({
    name: 'taskList',
    initialState: {
        error: null,
        taskListloading: false,
        taskListData: [],
        shortcutsData: []
    },
    reducers: {
        taskListRequest: (state) => {
            state.taskListloading = true;
            state.error = null;
        },
        taskListSuccess: (state, action) => {
            state.taskListloading = false;
            state.taskListData = action.payload;
        },
        taskListFailure: (state, action) => {
            state.taskListloading = false;
            state.error = action.payload;
        },
        shortcutsRequest: (state) => {
            state.shortcutsloading = true;
            state.error = null;
        },
        shortcutsSuccess: (state, action) => {
            state.shortcutsloading = false;
            state.shortcutsData = action.payload;
        },
        shortcutsFailure: (state, action) => {
            state.shortcutsloading = false;
            state.error = action.payload;
        },
        deleteTaskSuccess: (state, action) => {
            state.taskListData = state.taskListData.filter(task => task.id != action.payload)


        },
        updateTaskRating: (state, action) => {
            const { id, rating } = action?.payload

            state.taskListData = state.taskListData.map(task =>

                task.id === id ? { ...task, rating: !rating } : task
            );
        },

        moveTaskRowUp: (state,action) => {
            const index =action?.payload
            if (index > 0) {
                const updatedRows = [...state.taskListData];
                [updatedRows[index - 1], updatedRows[index]] = [updatedRows[index], updatedRows[index - 1]];
                state.taskListData = updatedRows
            }
        },

        moveTaskRowDown: (state,action) => {
            const index =action?.payload
            console.log(index,action?.payload);
            if (index < state.taskListData.length - 1) {
                const updatedRows = [...state.taskListData];
                [updatedRows[index], updatedRows[index + 1]] = [updatedRows[index + 1], updatedRows[index]];
             
                   state.taskListData= updatedRows
                 

            }
        }
    },
});

export const { taskListRequest, taskListSuccess, taskListFailure, shortcutsRequest, shortcutsSuccess, shortcutsFailure, deleteTaskSuccess, updateTaskRating,moveTaskRowDown,moveTaskRowUp } = taskSlice.actions;

export default taskSlice.reducer;
