// slices/authSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { deleteCookies } from "../utils/helpers/deleteCookies";


const dashboardSlice = createSlice({
    name: 'hotList',
    initialState: {
        user: null,
        error: null,
        hotListloading: false,
        hotListData:[],
        activeRequisiton:[],
        actionNotifications:[],
        actionNotificationloading:[]
    },
    reducers: {
        hotListRequest: (state) => {
            state.hotListloading = true;
            state.error = null;
        },
        hotListSuccess: (state, action) => {
            state.hotListloading = false;
            state.hotListData = action.payload;
        },
        hotListFailure: (state, action) => {
            state.hotListloading = false;
            state.error = action.payload;
        },
        actionNotificationRequest: (state) => {
            state.actionNotificationloading = true;
            state.error = null;
        },
        actionNotificationSuccess: (state, action) => {
            state.actionNotificationloading = false;
            state.actionNotifications = action.payload;
        },
        actionNotificationFailure: (state, action) => {
            state.actionNotificationloading = false;
            state.error = action.payload;
        },
        activeListRequest: (state) => {
            state.activeListloading = true;
            state.error = null;
        },
        activeListSuccess: (state, action) => {
            state.activeListloading = false;
            state.activeListData = action.payload;
        },
        activeListEmpty:(state,action)=>{
            state.activeListData=[]
        },
        activeListFailure: (state, action) => {
            state.activeListloading = false;
            state.error = action.payload;
        },
        dashboardRequest:(state,action)=>{
            state.loading=true;
            state.error=false;
        },
        dashboardSuccess:(state,action)=>{
            state.dashboardData=action?.payload;
            state.loading=false;
            state.error=false;
        },
        dashboardError:(state,action)=>{
            state.error=action.payload;
            state.loading=false;
        }
    },
});

export const { hotListRequest, hotListSuccess,activeListEmpty, hotListFailure,activeListRequest, activeListSuccess, activeListFailure,dashboardRequest, dashboardSuccess, dashboardError } = dashboardSlice.actions;

export default dashboardSlice.reducer;
