import { call, put, takeLatest } from "redux-saga/effects";
import api from "../api/api";
import { getCommentData, getProfileDetailInfo, setCommentData, setProfileDetail, setProfileError,setFindProject, setResumeData } from "../slices/requisitonProfileSlice";
import { setUserListData } from "../slices/requisitionSlice";
import { CommonError } from "../helpers/toastMethod";

function* fetchProfileInfo(action) {
    try {
      const response = yield call(apiProfileInfo, action.payload);
      yield put(setProfileDetail(response.data));
    } catch (error) {
      CommonError('Something Went Wrong')
      yield put(setProfileError(error));
    }
  }
  function* fetchCommentData(action) {
    try {
      const response = yield call(apiCommentData, action.payload);
      yield put(setCommentData(response.data));
    } catch (error) {
      yield put(setProfileError(error));
    }
  }
  function* fetchUserListData(){
    try{
      const response=yield call(apiUsersListData)
      yield put(setUserListData(response.data))
    }
    catch (error) {
      yield put(setProfileError(error));
    }
  }
  function *fetchGetFindProject(action){
    try{
      const response=yield call(apiFindProject,action)
      yield put(setFindProject(response.data));
    }
    catch (error) {
      yield put(setProfileError(error));
    }
  }
  function *fetchResume(action){
    try{
      const response=yield call(apiGetResume,action.id)
      yield put(setResumeData(response.data));
    }
    catch (error) {
      yield put(setProfileError(error));
    }
  }


  export function* ProfileInfoSaga() {

    yield takeLatest(getProfileDetailInfo, fetchProfileInfo);
  }
  export function* ProfileCommentSaga() {

    yield takeLatest(getCommentData, fetchCommentData);
  }
  export function* ProfileUsersList(){
    yield takeLatest('Requisition/GetAllEmployees',fetchUserListData)
  }
  export function* GetFindProjectSaga() {

    yield takeLatest('Requisition/GetFindProject', fetchGetFindProject);
  }
  export function* GetRecentResumeSaga() {

    yield takeLatest('Requisition/GetRecentResumeFile', fetchResume );
  }
 
  
  

  function apiProfileInfo(body){
    return api.get('Requisition/GetReqCandidateProfileData/'+body?.profileID)
  }
  function apiCommentData(body){
    return api.post('Requisition/GetCommentData',body)
  }
  function apiUsersListData(){
    return api.get('Requisition/GetAllEmployees')
  }
  function apiFindProject(body){
    return api.get('Requisition/GetFindProjects/'+body?.profileID+'/'+body?.UserId)
  
  }
  function apiGetResume(body){
    return api.get('Requisition/getPDFResumeFile/'+body)
  
  }