// dispositionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'disposition',
  initialState: {
    error: null,
    loadingCompany: false,
    isSearch: false,
    companyInfo:[],
    stockInfo:[]

  },
  reducers: {
    setCompanyInfoData: (state, action) => {
      state.companyInfo = action.payload;
      state.error = false;
      state.loadingCompany = false;

    },
    setStockInfoData: (state, action) => {
      state.stockInfo = action.payload;
      state.error = false;
    },
    retaCompanyInfoTreeRequest: (state) => {
      state.loadingCompany = true;
      state.error = null;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCompanyError: (state, action) => {
      state.error = action.payload;
      state.loadingCompany = false;

    },
  },
});

export const { setData, setCompanyError, setStockInfoData, retaCompanyInfoTreeRequest, setCompanyInfoData } = companySlice.actions;
export default companySlice.reducer;
