import { call, put, takeLatest } from "redux-saga/effects";
import { activeListFailure, activeListRequest, activeListSuccess, dashboardError, dashboardRequest, dashboardSuccess, hotListFailure, hotListRequest, hotListSuccess } from "../slices/dashboardSlice";
import api from "../api/api";
import { shortcutsFailure, shortcutsRequest, shortcutsSuccess, taskListFailure, taskListRequest, taskListSuccess } from "../slices/dashboard/taskSlice";
import { notificationListFailure, notificationListRequest, notificationListSuccess } from "../slices/dashboard/notificationSlice";

function* fetchHotList() {
  try {
    const response = yield call(apiGetHotList)
    yield put(hotListSuccess(response.data));
  }
  catch (error) {
    yield put(hotListFailure(error));
  }
}
function* fetchPRPData(action) {
  try {
    const response = yield call(apiGetPRPData, action?.payload);
    yield put(dashboardSuccess(response.data));
  }
  catch (error) {
    yield put(dashboardError(error));
  }
}
function* fetchActiveRequisitonList(action) {
  try {
    const response = yield call(apiGetActiveRequisiton, action?.payload)
    yield put(activeListSuccess(response.data));
  }
  catch (error) {
    yield put(activeListFailure(error));
  }
}
function* fetchShortCut(action) {
  try {
    const response = yield call(apiRetrieveShortcutURLs, action?.payload)
    yield put(shortcutsSuccess(response.data));
  }
  catch (error) {
    yield put(shortcutsFailure(error));
  }
}
function* fetchActionNotifications(action) {
  try {
    const response = yield call(apiGetActionItemNotification, action?.payload)
    yield put(notificationListSuccess(response.data));
  }
  catch (error) {
    yield put(notificationListFailure(error));
  }
}


// Task Related Functions
function* fetchTaskData(action) {
  try {
    const response = yield call(apiGetTasksData, action?.payload)
    yield put(taskListSuccess(response.data));
  }
  catch (error) {
    yield put(taskListFailure(error));
  }
}
function* updateTaskData(action) {
  try {
    const response = yield call(apiGetTasksData, action?.payload)
    yield put(taskListSuccess(response.data));
  }
  catch (error) {
    yield put(taskListFailure(error));
  }
} function* deleteTaskData(action) {
  try {
    const response = yield call(apiGetTasksData, action?.payload)
    yield put(taskListSuccess(response.data));
  }
  catch (error) {
    yield put(taskListFailure(error));
  }
}

export function* GetHotListSaga() {
  yield takeLatest(hotListRequest, fetchHotList);
}
export function* GetDashboardData() {
  yield takeLatest(dashboardRequest, fetchPRPData);
}
export function* GetActionItems() {
  yield takeLatest(notificationListRequest, fetchActionNotifications);
}
export function* GetShorcutsData() {
  yield takeLatest(shortcutsRequest, fetchShortCut)
}
export function* GetActiveRequisiton() {
  yield takeLatest(activeListRequest, fetchActiveRequisitonList);
}
// Task Related Apis
export function* GetTasksData() {
  yield takeLatest(taskListRequest, fetchTaskData);
}
export function* DeleteTaskTask() {
  yield takeLatest('MainDashBoard/DeleteTask', deleteTaskData);
}



function apiGetHotList() {
  return api.get('MainDashBoard/GetHotListProfiles')
}
function apiGetPRPData(body) {
  return api.get('MainDashBoard/GetPRPData/' + body)
}
function apiGetActiveRequisiton(body) {
  return api.get('MainDashBoard/GetUserRequisitionsData/' + body)
}
function apiRetrieveShortcutURLs(body) {
  return api.get('MainDashBoard/RetrieveShortcutURLsByEmployeeId/' + body)
}
function apiGetActionItemNotification(body) {
  return api.get('MainDashBoard/GetActionItems/' + body?.id + '/' + body?.show)
}

// Task Related APis
function apiGetTasksData(body) {
  return api.get('MainDashBoard/GetTasksDataByEmployeeId/' + body)
}
export function apiCreateTask(body) {
  return api.post('MainDashBoard/InsertTask', body)
}
export function apiDeleteTask(body) {
  return api.delete('MainDashBoard/DeleteTask/' + body)
}
export function apiUpdateTaskRating(body) {
  return api.put('MainDashBoard/UpdateRating/' + body?.id + '/' + !body?.rating)
}
export function apiUpdateTask(body) {
  return api.put('MainDashBoard/UpdateTask/', body)
}
export function apiMoveTaskInfo(body) {
  return api.post('MainDashBoard/MoveTaskInfo/', body)
}

//Reminders Related APi
export function apiGetReminders(body) {
  return api.get('/MainDashBoard/GetReminders/' + body?.employeeId + '/' + body?.period)

}
export function apiInsertReminders(body) {
  return api.post('MainDashBoard/InsertReminder/', body)
}
export function apiUpdateReminder(body) {
  return api.post('MainDashBoard/UpdateReminder/', body)
}