// dispositionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const requisitionSlice = createSlice({
  name: "disposition",
  initialState: {
    successMoved: false,
    ProfileList: [],
    retaTreeData: [],
    data: null,
    error: null,
    loading: false,
    isSearch: false,
    selectedprofiles: [{}],
  },
  reducers: {
    setMovingProfile: (state) => {
      state.movingProfile = true;
      state.successMoved = false;
    },
    setSelectedProfile: (state, action) => {
      state.selectedprofiles = action.payload;
    },
    setMovedProfile: (state) => {
      state.movingProfile = false;
      state.successMoved = true;
    },
    setCheck: (state) => {},
    setSelect1InfoData: (state, action) => {
      console.log(action.payload);
      state.rejectSelect1 = action.payload;
      state.error = false;
      state.loading = false;
    },
    setRequisitionInfoData: (state, action) => {
      state.requistionInfo = action.payload;
      state.error = false;
      state.loadingInfo = false;
    },

    retaCustomTreeRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    retaRequisitionInfoRequest: (state) => {
      state.error = null;
      state.loadingInfo = true;
    },
    retaTreeRequest: (state, action) => {
      if (action.payload.searchedProfile.length)
        state.isSearch = action.payload.searchedProfile;
      else state.isSearch = false;
      state.loading = true;
      state.error = null;
    },
    setRetaTreeData: (state, action) => {
      console.log(action?.payload, "data===");
      state.averageFillTime = action.payload.AverageFillTime;
      state.retaTreeData = action.payload.Data;
      state.loading = false;
    },
    setAssociateData: (state, action) => {
      state.associateSelectData = action.payload;
      state.error = false;
    },
    retaTreeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUserListData: (state, action) => {
      state.ProfileList = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selected = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loadingInfo = false;
      state.loading = false;
      state.successMoved = false;
    },
  },
});

export const {
  setData,
  setError,
  setSelectedSection,
  setUserListData,
  setRetaTreeData,
  setStockInfoData,
  setSelect1InfoData,
  retaTreeFailure,
  retaTreeRequest,
  setAssociateData,
  retaCustomTreeRequest,
  retaCompanyInfoTreeRequest,
  setCompanyInfoData,
  setMovingProfile,
  setMovedProfile,
  retaRequisitionInfoRequest,
  setRequisitionInfoData,
  setSelectedProfile,
} = requisitionSlice.actions;
export default requisitionSlice.reducer;
