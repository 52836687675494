import { createSlice } from "@reduxjs/toolkit";

const retaButtonSlice = createSlice({
  name: "retaButton",
  initialState: {
    showMenu: false,
    expandAll: true,
  },
  reducers: {
    toggleShowSideMenu: (state) => {
      state.showMenu = !state.showMenu;
    },
    toggleExpandAll: (state) => {
      state.expandAll = !state.expandAll;
    },
  },
});
export const { toggleShowSideMenu, toggleExpandAll } = retaButtonSlice.actions;
export default retaButtonSlice.reducer;
