// dispositionSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  retaCustomTreeRequest,
  retaRequisitionInfoRequest,
  retaTreeRequest,
  setAssociateData,
  setData,
  setError,
  setMovedProfile,
  setMovingProfile,
  setRequisitionInfoData,
  setRetaTreeData,
  setSelect1InfoData,
} from "../slices/requisitionSlice";
import api from "../api/api";
import { toast } from "react-toastify";
import {
  retaCompanyInfoTreeRequest,
  setCompanyError,
  setCompanyInfoData,
  setStockInfoData,
} from "../slices/companySlice";

function* fetchDispositionData() {
  try {
    const response = yield call(apiDepositionDataFunction, {
      isSysAdmin: true,
      isMSM: true,
    });

    yield put(setData(response.data));
  } catch (error) {
    yield put(setError(error));
  }
}
function* fetchRetaCustomData(action) {
  try {
    const response = yield call(apiCustomRetaTree, action.payload);

    if (response?.data) {
      yield put(setRetaTreeData(response.data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}
function* fetchRetaData(action) {
  try {
    let response = {};
    const isSmall = action?.payload?.isSmall;
    if (isSmall) {
      response = yield call(apiRetaTree, action.payload);
      const data = {
        AverageFillTime: response.data?.AverageFillTime,
        Data: response?.data?.Data,
      };
      yield put(setRetaTreeData(data));
    } else {
      response = yield call(apiGetRetaTreeNEW, action.payload);
      const data = {
        AverageFillTime: response?.data?.AverageFillTime,
        Data: response?.data?.NData,
      };
      yield put(setRetaTreeData(data));
    }
  } catch (error) {
    yield put(setError(error));
  }
}
function* fetchAssociateSelect() {
  try {
    const response = yield call(apissociateToReq);

    yield put(setAssociateData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchCompanyInfo(action) {
  try {
    const response = yield call(apiCompanyInfo, action.payload);
    yield put(setCompanyInfoData(response.data));
  } catch (error) {
    console.log("wrong");
    CommonError("Something Went Wrong");
    yield put(setCompanyError(error));
  }
}
function* fetchStockInfo(action) {
  console.log(action?.id);
  try {
    const response = yield call(apiStockInfo, action?.id);
    yield put(setStockInfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setCompanyError(error));
  }
}
function* fetchRequisitionInfo(action) {
  try {
    const response = yield call(apiRequisitionInfo, action.payload);
    yield put(setRequisitionInfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* GetMoveProfile(action) {
  try {
    const response = yield call(apiMoveProfile, action.payload);
    console.log(response, "resposne");
    if (response?.data === -1) {
      CommonError("Access Denied");
      setError("Access Denied");
      return;
    }
    yield put(setMovedProfile());
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchMoveProfileSelectData1(action) {
  try {
    const response = yield call(apiMoveProfileSelectData1, action.payload);
    // console.log(response,'resposne');
    yield put(setSelect1InfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}
function* fetchProfileByReqId(action) {
  try {
    const response = yield call(apiGetProfilesbyByReqID, action.payload);
    // console.log(response,'resposne');
    yield put(setSelect1InfoData(response.data));
  } catch (error) {
    CommonError("Something Went Wrong");
    yield put(setError(error));
  }
}

export function* dispositionSaga() {
  yield takeLatest("disposition/fetchData", fetchDispositionData);
}
export function* CustomRetaTreeSaga() {
  yield takeLatest(retaCustomTreeRequest, fetchRetaCustomData);
}
export function* RetaTreeSaga() {
  yield takeLatest(retaTreeRequest, fetchRetaData);
}
export function* associateSelectSaga() {
  yield takeLatest("Requisition/GetRequisitions", fetchAssociateSelect);
}
export function* CompanyInfoSaga() {
  yield takeLatest(retaCompanyInfoTreeRequest, fetchCompanyInfo);
}
export function* StockInfoSaga() {
  yield takeLatest("Requisition/GetStockInformation", fetchStockInfo);
}
export function* GetMoveProfileSaga() {
  yield takeLatest(setMovingProfile, GetMoveProfile);
}
export function* RequisitionInfoSaga() {
  yield takeLatest(retaRequisitionInfoRequest, fetchRequisitionInfo);
}
export function* RejectSelect1Saga() {
  yield takeLatest("Requisition/GetReasonCodes", fetchMoveProfileSelectData1);
}
export function* fetchProfilesFromReq() {
  yield takeLatest("disposition/fetchData", fetchDispositionData);
}

function apiDepositionDataFunction(userData) {
  return api.post("disposition/populate", userData);
}
function apiRetaTree(body) {
  return api.post("Requisition/reta-tree", body);
}
function apissociateToReq() {
  return api.get("Requisition/GetRequisitions");
}
function apiCustomRetaTree(body) {
  return api.post("Requisition/GetCustomProject", body);
}
function apiCompanyInfo(body) {
  return api.get("Requisition/GetReqCompanydata/" + body);
}
function apiStockInfo(body) {
  console.log(body);
  return api.get("Requisition/GetStockInformation/" + body);
}
function apiMoveProfile(body) {
  return api.post("Requisition/GetMoveProfileToOtherLevel", body);
}
function apiRequisitionInfo(body) {
  return api.get("Requisition/GetReqInformation/" + body?.reqID);
}
function apiMoveProfileSelectData1(body) {
  return api.get(`Requisition/GetReasonCodes`);
}
export function apiGetProfilesbyByReqID(body) {
  const queryParams =
    body && body.isSearch ? `&searchedProfile=${body.isSearch}` : "";
  return api.get(
    `Requisition/GetProfilesbyByReqID/?ReqID=${body?.id}${queryParams}`
  );
}
export function apiGetRetaTreeNEW(body) {
  return api.post("Requisition/reta-tree-New", body);
}

const CommonError = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
