import { createSlice } from "@reduxjs/toolkit";


const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        error: null,
        notificationloading: false,
        notificationListData:[],
        shortcutsData:[]
    },
    reducers: {
        notificationListRequest: (state) => {
            state.notificationloading = true;
            state.error = null;
        },
        notificationListSuccess: (state, action) => {
            state.notificationloading = false;
            state.notificationListData = action.payload;
        },
        notificationListFailure: (state, action) => {
            state.notificationloading = false;
            state.error = action.payload;
        },
    }
})

export const { notificationListFailure,notificationListRequest,notificationListSuccess } = notificationSlice.actions;

export default notificationSlice.reducer;

