import { createSlice } from '@reduxjs/toolkit';

const requisitionProfileSlice = createSlice({
    name: 'reqProfile',
    initialState: {
        profileDetail: [],
        loading: false,
        error: false,
        loadingComments: false,
        foundProject:[]

    },
    reducers: {
        getProfileDetailInfo: (state) => {
            state.loading = true;
            state.error = false;
        },
        setProfileDetail: (state, action) => {
            state.profileDetail = action.payload;
            state.loading = false;
            state.error = false;
        },
        setProfileError: (state, action) => {
            state.error = action.payload;
            state.loading = false;

        },
        setCommentData: (state, action) => {
            state.commentData = action.payload;
            state.loadingComments = false
        },
        getCommentData: (state) => {
            state.loadingComments = true
        },
        emptyProfileData: (state) => {
            state = {
                profileDetail: [],
                loading: false,
                error: false,
                loadingComments: false,


            }
        },
        setFindProject: (state, action) => {
            state.foundProject = action.payload;
            state.loading = false
        },
        setResumeData:(state,action)=>{
            state.profileDetail['resume']=action.payload

        }

    }
});
export const { getProfileDetailInfo,setResumeData, setFindProject, setProfileDetail, setCommentData, getCommentData, setProfileError, emptyProfileData } = requisitionProfileSlice.actions;
export default requisitionProfileSlice.reducer;