// sagas/authSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginFailure, loginRequest, loginSuccess } from '../slices/authSlice';
import api from '../api/api';

function* loginSaga(action) {
  try {
    
    const response = yield call(apiLoginFunction, action.payload);
    const data={userJwt:getCookie('UserLoginCookie'),firstName:getCookie('FirstName'),lastName:getCookie('LastName')}
    const cookieString = document.cookie;
    const cookieArray = cookieString.split('; ');
    const cookieObject = {};
    for (const cookie of cookieArray) {
      const [name, value] = cookie.split('=');
      cookieObject[name] = value;
    }
    if(data?.firstName && data?.lastName)
    yield put(loginSuccess(cookieObject));
  else
    yield put(loginFailure('Something went wrong'));

  } catch (error) {
    if (error?.response?.status && error?.response?.status  === 401)
      yield put(loginFailure('Invalid credentials'));
    else {

      yield put(loginFailure(error?.message||'unexpected error occured'));

    }
  }
}

function apiLoginFunction(userData) {
  return api.post('Authentication/VerifyCredentials', userData);
}

export function* watchLogin() {
  yield takeLatest(loginRequest, loginSaga);
}
function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  console.log(decodedCookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // Return null if the cookie with the given name is not found
}