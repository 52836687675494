// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import authReducer from "../slices/authSlice";
import requisitionReducer from "../slices/requisitionSlice";
import requisitionProfileReducer from "../slices/requisitonProfileSlice";
import companyProfileReducer from "../slices/companySlice";
import dashboardReducer from "../slices/dashboardSlice";
import DashboardTaskReducer from "../slices/dashboard/taskSlice";
import NotificationReducer from "../slices/dashboard/notificationSlice";
import RetaButtonReducer from "../slices/retaButtonSlice";

import { watchLogin } from "../sagas/authSaga";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  CompanyInfoSaga,
  CustomRetaTreeSaga,
  GetMoveProfileSaga,
  RejectSelect1Saga,
  RequisitionInfoSaga,
  RetaTreeSaga,
  StockInfoSaga,
  associateSelectSaga,
  dispositionSaga,
} from "../sagas/retaSaga";
import { all, fork } from "redux-saga/effects";
import {
  GetFindProjectSaga,
  GetRecentResumeSaga,
  ProfileCommentSaga,
  ProfileInfoSaga,
  ProfileUsersList,
} from "../sagas/profileSaaga";
import {
  CreateTask,
  GetActionItems,
  GetActiveRequisiton,
  GetDashboardData,
  GetHotListSaga,
  GetPRPData,
  GetShorcutsData,
  GetTasksData,
} from "../sagas/dashboardSaga";

const persistConfig = {
  key: "auth",
  storage,
  // stateReconciler: autoMergeLevel2, // Use autoMergeLevel2 state reconciler
  whitelist: ["user"],
};
const requisitionConfig = {
  key: "requisition",
  storage,
  whitelist: [],
  // stateReconciler: autoMergeLevel2, // Use autoMergeLevel2 state reconciler
};
const requisitionProfileConfig = {
  key: "reqProfile",
  storage,
  whitelist: [],
  // stateReconciler: autoMergeLevel2, // Use autoMergeLevel2 state reconciler
};
const companyProfileConfig = {
  key: "compProfile",
  storage,
  whitelist: [],
  // stateReconciler: autoMergeLevel2, // Use autoMergeLevel2 state reconciler
};
const dashboardConfig = {
  key: "dashboard",
  storage,
  whitelist: [],
  // stateReconciler: autoMergeLevel2, // Use autoMergeLevel2 state reconciler
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "requisition", "reqProfile", "dashboard"],
};
const sagaMiddleware = createSagaMiddleware();
// const persistConfig = {
//   key: 'root', // The key to use in local storage
//   storage, // Use local storage as the storage engine
//   blacklist: ['auth.error'],
// };

const reducers = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  requisition: persistReducer(requisitionConfig, requisitionReducer),
  reqProfile: persistReducer(
    requisitionProfileConfig,
    requisitionProfileReducer
  ),
  compProfile: persistReducer(companyProfileConfig, companyProfileReducer),
  dashboard: persistReducer(dashboardConfig, dashboardReducer),
  taskList: DashboardTaskReducer,
  notification: NotificationReducer,
  retaButtons: RetaButtonReducer,
});

// Wrap reducers with the persistReducer
const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
});
function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(dispositionSaga),
    fork(RetaTreeSaga),
    fork(associateSelectSaga),
    fork(CustomRetaTreeSaga),
    fork(CompanyInfoSaga),
    fork(GetMoveProfileSaga),
    fork(RequisitionInfoSaga),
    fork(ProfileInfoSaga),
    fork(StockInfoSaga),
    fork(ProfileCommentSaga),
    fork(ProfileUsersList),
    fork(GetFindProjectSaga),
    fork(RejectSelect1Saga),
    fork(GetRecentResumeSaga),
    fork(GetDashboardData),
    fork(GetTasksData),
    fork(GetHotListSaga),
    fork(GetActiveRequisiton),
    fork(GetShorcutsData),
    fork(GetActionItems),
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;
