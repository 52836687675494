import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./components/Spinner";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { deleteCookies } from "./utils/helpers/deleteCookies";
import NotFound from "./pages/NotFound";
import "./App.css";
import "./css/wlTheme.css";
import "./css/custom.css";
const Reta = lazy(() => import("./pages/Reta"));
const Requisition = lazy(() => import("./pages/Reta/Reta"));

const ProData = lazy(() => import("./pages/ProData"));
const GridSearch = lazy(() => import("./pages/ProData/GridSearch"));

const SysAdmin = lazy(() => import("./pages/TogglePages/SysAdmin"));
const Dms = lazy(() => import("./pages/Dms"));
const ManageSetting = lazy(() => import("./pages/Dms/ManageWorldlinkSettings"));

const Projects = lazy(() => import("./pages/TogglePages/Projects"));
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/ProData/AddProfile"));
const AddRequistion = lazy(() => import("./pages/Reta/AddRequisition"));
const Company = lazy(() => import("./pages/Company"));
const ViewProfile = lazy(() => import("./pages/ProData/ViewProfile"));
const Resume = lazy(() => import("./pages/Resume"));

const routes = [
  {
    path: "/",
    component: <Home />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/prodata/viewprofile",
    component: <ViewProfile />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/ReTa",
    component: <Reta />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/ReTa/Reta",
    component: <Requisition />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/pro-data",
    component: <ProData />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/pro-data/GridSearch",
    component: <GridSearch />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/sys-admin",
    component: <SysAdmin />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/dms",
    component: <Dms />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/dms/ManageWorldlinkSettings",
    component: <ManageSetting />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/projects",
    component: <Projects />,
    exact: true,
    authGuard: true, // Private route
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
    authGuard: false, // Restricted to authenticated users
  },
  {
    path: "/pro-data/AddProfile",
    component: <Profile />,
    exact: true,
    header: true,
    authGuard: true, // Restricted to authenticated users
  },
  {
    path: "Reta/AddRequistion",
    component: <AddRequistion />,
    exact: true,
    authGuard: true, // Restricted to authenticated users
  },
  {
    path: "/Company",
    component: <Company />,
    exact: true,
    authGuard: true, // Restricted to authenticated users
  },
  {
    path: "/Shared/resume",
    component: <Resume />,
    exact: true,
    authGuard: true, // Restricted to authenticated users
  },
];

const App = () => {
  const userstate = useSelector((state) => state.auth.user);
  const documentCookies = document.cookie.split("; ").sort();
  const stateCookies = userstate
    ? Object.entries(userstate)
        .map(([name, value]) => `${name}=${value}`)
        .sort()
    : [];

  let isAuthenticated;
  const isAuth = () => {
    if (JSON.stringify(documentCookies) === JSON.stringify(stateCookies)) {
      isAuthenticated = true;
      return true;
    } else {
      deleteCookies();
      isAuthenticated = false;
      return false;
    }
  };

  return (
    <>
      <ToastContainer closeButton={true} />

      <Router>
        <Suspense fallback={<Spinner />}>
          <Routes>
            {routes.map((route, index) => (
              <>
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={
                    route.authGuard ? (
                      isAuth() ? (
                        route.component
                      ) : (
                        <Navigate to="/login" />
                      )
                    ) : !isAuth() ? (
                      route.component
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
              </>
            ))}
            <Route path={"*"} element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

export default App;
