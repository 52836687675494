import { toast } from "react-toastify";

export const CommonError=(msg)=>{
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
  export const CommonSuccess=(msg)=>{
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT
    });
  }